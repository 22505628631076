<template>
  <Breadcrumb
    v-if="navigationItems?.length > 1"
    :items="navigationItems"
    :color-mode="(colorMode as DeprecatedColorMode) === 'light' ? 'light' : 'dark' "
  />
</template>

<script setup lang="ts">
import { Breadcrumb } from '@hypercodestudio/basler-components';
import type { ColorMode } from '@hypercodestudio/basler-components/dist/models/ColorMode';
import type { ColorMode as DeprecatedColorMode } from '@hypercodestudio/basler-components/dist/components/modules/Breadcrumb.vue';
import type { NavigationItemInterface } from '@hypercodestudio/basler-components/dist/models/navigation/NavigationItem';
import type { BreadcrumbList, ListItem, WithContext } from 'schema-dts';
import { isDefined } from '~/utils/guards/isDefined';
import { useCreateNavItem } from '~/composables/useCreateMainNavItem';
import { isNavigationItemFragment } from '~/utils/guards/isNavigationItemFragment';
import type { NavigationItemFragment } from '~/lib/ContentfulGraphqlService';
import { deepExtract } from '~/utils/deepExtract';

interface Props {
  colorMode: DeprecatedColorMode | ColorMode; // TODO: remove light/dark typefix
  slug?: string;
}

const props = defineProps<Props>();

const locale = useLocale();
const { $globalPageSettings } = useNuxtApp();

const parents = computed(() => {
  let parents: NavigationItemFragment[] = [];

  if ($globalPageSettings.value) {
    deepExtract(
      $globalPageSettings.value.headerMainNavigation,
      (value, _, _parents, stop) => {
        if (
          isNavigationItemFragment(value) &&
          value.internalPage?.metadata?.slug === props.slug
        ) {
          parents = [..._parents.filter(isNavigationItemFragment), value];
          stop();
        }

        return false;
      }
    );
  }

  return parents;
});

const homeEntry: NavigationItemInterface = {
  // XXX: text is not visible for first entry
  text: 'Home',
  link: {
    uri: buildUrlString(locale.value)
  }
};
const navigationItems = computed<NavigationItemInterface[]>(() => [
  homeEntry,
  ...parents.value
    .map((item) => useCreateNavItem(item, false, true))
    .filter(isDefined)
]);

const breadcrumbList = computed<WithContext<BreadcrumbList>>(() => ({
  '@context': 'https://schema.org',
  '@type': 'BreadcrumbList',
  itemListElement: [
    {
      text: 'Home',
      link: {
        uri: buildUrlString(locale.value, undefined, undefined, undefined, true)
      }
    },
    ...parents.value
      .map((item) => useCreateNavItem(item, true))
      .filter(isDefined)
  ].map<ListItem>((item, index) => ({
    '@type': 'ListItem',
    name: item.text,
    position: index + 1,
    item: typeof item.link?.uri === 'string' ? item.link?.uri : ''
  }))
}));

useHead({
  script: [
    {
      type: 'application/ld+json',
      innerHTML: JSON.stringify(breadcrumbList.value),
      tagPriority: -1 // Optional: Sicherstellen, dass es zuletzt eingefügt wird
    }
  ]
});
</script>
